<!-- this must be the user shared component also but the scooter has multiple users so let check how we can fix that-->
<template>
  <div
    class="single-scooter-page"
  >
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="customer-image-column"
        >
          <b-row>
            <b-col
              cols="12"
              md="8"
              class="no-p-r no-p-l"
            >
              <div class="pl-1 float-left">
                <b-avatar
                  rounded
                  size="100px"
                />
              </div>

              <div class="user-data ml-1 float-left no-p-r">
                <div
                  v-b-tooltip="'Goto Customer Page'"
                  class="data_wrapper username_wrapper link-wrapper"
                  @click="$router.push({name: 'view-customer', params: { id: currentCustomer.id }})"
                >
                  {{ currentCustomer.name || '-' }}
                </div>
                <div
                  v-b-tooltip="'Goto Customer Page'"
                  class="data_wrapper usernike_wrapper link-wrapper"
                  @click="$router.push({name: 'view-customer', params: { id: currentCustomer.id }})"
                >
                  {{ currentCustomer.username || '-' }}
                </div>
                <div class="reset_password_button mt-1">
                  <b-button
                    @click="resetPassword"
                  >Reset Password</b-button>
                </div>
              </div>

            </b-col>

            <b-col
              cols="12"
              md="4"
              class="scooter-icons no-p-r no-p-l"
            >

              <b-col
                class="icon-column"
                cols="6"
                md="12"
              >
                <b-row class="scooter-icon-wrapper no-hr-spaces no-p-r no-p-l">
                  <div class="icon scooter-icon mr-1" />
                  <div class="col-6 icons-data no-p-r no-p-l">
                    <div class="number">
                      {{ currentCustomer.rides }}
                    </div>
                    <div class="title">
                      Rides
                    </div>
                  </div>
                </b-row>
              </b-col>

              <b-col
                class="icon-column"
                cols="6"
                md="12"
              >
                <b-row class="scooter-icon-wrapper no-hr-spaces no-p-r no-p-l">
                  <div class="icon distance-icon mr-1" />
                  <div class="col-6 icons-data no-p-r no-p-l">
                    <div class="number">
                      {{ currentCustomer.distance }}km
                    </div>
                    <div class="title">
                      Distance
                    </div>
                  </div>
                </b-row>
              </b-col>

            </b-col>

            <b-col
              v-if="currentCustomer.scooter_types && currentCustomer.scooter_types.length > 0"
              cols="7"
              class="mt-1"
            >
              <b-badge
                v-for="(customer_scooter,index) in currentCustomer.scooter_types"
                :key="`scooter-${index}`"
                variant="light-primary"
                class="scooter-badge"
              >
                {{ customer_scooter }}
              </b-badge>
            </b-col>
          </b-row>

        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-row class="mt-xs-1">
            <b-col cols="6">

              <div class="row user-data-row">
                <b-col cols="12">
                  <span>
                    <span class="label">
                      Status:
                    </span>
                    <b-badge
                      v-if="!currentCustomer.is_blocked"
                      v-b-tooltip="'Block Customer'"
                      class="status-text customer-badge link-wrapper"
                      variant="light-success"
                      @click="LockCustomer"
                    >
                      Active
                    </b-badge>
                    <b-badge
                      v-else
                      v-b-tooltip="'Unblock Customer'"
                      class="customer-badge link-wrapper"
                      variant="light-danger"
                      @click="UnLockCustomer"
                    >
                      Block
                    </b-badge>
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <b-row>
                    <div class="pl-1">
                      <span class="label">
                        Email:
                      </span>
                    </div>

                    <b-col>
                      <span class="data">
                        <div
                          id="emails-menu-handler"
                          @click="showEmailMenu = !showEmailMenu"
                        >
                          <span class="customer-emails-holder">
                            {{ currentCustomer.email || '-' }}
                          </span>
                          <span
                            v-if="(customer.customers.length > 1)"
                            class="caret-span"
                          >&#8964;</span>
                        </div>
                      </span>
                    </b-col>

                    <b-row
                      v-if="(showEmailMenu && customer.customers.length > 1)"
                      class="d-flex email-dropdown-menu w-100"
                    >
                      <div class="font-weight-bold d-block w-100 text-left pl-0-75 previous-emails-titles">
                        Previous emails:
                      </div>
                      <ul class="list-unstyled text-left email-listing w-100 d-block clearfix">
                        <li
                          v-for="(customer,index) in customer.customers"
                          :key="index"
                          class="customer-email-item"
                        >
                          <a
                            class="customer-email-item-link"
                            href="#"
                            @click="setCurrentCustomer(index)"
                          >{{ customer.email }}</a>
                        </li>
                      </ul>
                    </b-row>
                  </b-row>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Subscription:
                  </span>
                  <span class="data">
                    {{ currentCustomer.is_subscribed ? 'Yes' : 'No' }}
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Country:
                  </span>
                  <span class="data">
                    {{ currentCustomer.country && currentCustomer.country.name ? currentCustomer.country.name : '-' }}
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Account created:
                  </span>
                  <span class="data">{{ currentCustomer && ((currentCustomer.joined_date && customer_joined_date) || '-') }}</span>
                </b-col>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="row user-data-row">
                <b-col cols="12">
                  <b-row>
                    <div class="pl-1">
                      <span class="label">
                        Phone:
                      </span>
                    </div>

                    <b-col v-if="currentCustomer.devices !== undefined && currentCustomer.devices.length > 0">
                      <span class="data">
                        <div
                          id="phones-menu-handler"
                          @click="showPhoneMenu = !showPhoneMenu"
                        >
                          <span class="customer-phones-holder">
                            {{ currentCustomer.devices[0].model }}
                          </span>
                          <span
                            v-if="(currentCustomer.devices.length > 1)"
                            class="caret-span"
                          >&#8964;</span>
                        </div>
                      </span>
                    </b-col>

                    <b-row
                      v-if="(showPhoneMenu && currentCustomer.devices !== undefined && currentCustomer.devices.length > 1)"
                      class="d-flex phone-dropdown-menu w-100"
                    >
                      <div class="font-weight-bold d-block w-100 text-left pl-0-75 previous-phones-titles">
                        Previous phones:
                      </div>
                      <ul
                        class="list-unstyled text-left phone-listing w-100 d-block clearfix"
                        :class="currentCustomer.devices.length > 6 ? 'more-phones' : ''"
                      >
                        <li
                          v-for="(device,index) in currentCustomer.devices"
                          :key="index"
                          class="customer-phone-item"
                        >
                          {{ device.model }}
                        </li>
                      </ul>
                    </b-row>

                    <span v-if="(currentCustomer.devices === undefined || currentCustomer.devices.length <= 0)">-</span>

                  </b-row>
                </b-col>
              </div>
              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    SDK:
                  </span>
                  <span class="data">
                    {{ currentCustomer.device_info && currentCustomer.device_info.sdk ? currentCustomer.device_info.sdk : '-' }}
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Model:
                  </span>
                  <span class="data">
                    {{ currentCustomer.device_info && currentCustomer.device_info.model ? currentCustomer.device_info.model : '-' }}
                  </span>
                </b-col>
              </div>
              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Memory:
                  </span>
                  <span class="data">
                    {{ currentCustomer.device_info && currentCustomer.device_info.ram_av ? currentCustomer.device_info.ram_av : '-' }}
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Type:
                  </span>
                  <span class="data">
                    {{ currentCustomer.device_info && currentCustomer.device_info.device ? currentCustomer.device_info.device : 'React' }}
                  </span>
                </b-col>
              </div>

              <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    App version:
                  </span>
                  <span class="data">
                    {{ currentCustomer.device_info && currentCustomer.device_info.app_version ? currentCustomer.device_info.app_version : '-' }}
                  </span>
                </b-col>
              </div>

              <!-- <div class="row user-data-row">
                <b-col cols="12">
                  <span class="label">
                    Pin:
                  </span>
                  <span class="data">
                    {{ customer.password ? customer.password : '-' }}
                  </span>
                </b-col>
              </div> -->
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BCol, BRow, BAvatar, BButton, BBadge,
} from 'bootstrap-vue'
import Moment from 'moment'

export default {
  components: {
    BAvatar,
    BCard,
    BCol,
    BRow,
    BButton,
    BBadge,
    Moment,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    customer: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      showEmailMenu: false,
      showPhoneMenu: false,
      currentCustomer: {},
    }
  },
  computed: {
    customer_joined_date() {
      // return `${Moment(this.currentCustomer.joined_date).format('D MMMM YYYY')} (${Moment(this.currentCustomer.joined_date).fromNow()})`
      if (!this.currentCustomer.joined_date || this.currentCustomer.joined_date === '') return '-'
      return `${Moment(this.currentCustomer.joined_date).format('D MMMM YYYY')}`
    },
  },
  mounted() {
    this.setCurrentCustomer()
  },
  created() {
    window.addEventListener('click', this.windowClicked)
  },

  destroyed() {
    window.removeEventListener('click', this.windowClicked)
  },
  methods: {
    resetPassword() {
      this.$http.post(this.$resources.customers.resetPassword, {
        email: this.currentCustomer.email,
      }).then(() => {
        this.$notify({ type: 'success', title: 'Reset password', text: 'Reset password message sent successfully' })
      })
    },

    LockCustomer() {
      this.$swal({
        title: 'Block Customer?',
        text: 'Would you like to block this customer?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.post(this.$resources.customers.unblock, {
            email: this.currentCustomer.email,
          }).then(() => {
            this.currentCustomer.is_blocked = true
            this.$notify({ type: 'success', title: 'Block user', text: 'User blocked successfully' })
          })
        }
      })
    },

    UnLockCustomer() {
      this.$swal({
        title: 'Unblock Customer?',
        text: 'Would you like to unblock this customer?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.post(this.$resources.customers.unblock, {
            email: this.currentCustomer.email,
          }).then(() => {
            this.currentCustomer.is_blocked = false
            this.$notify({ type: 'success', title: 'Activate user', text: 'User activated successfully' })
          })
        }
      })
    },
    windowClicked(event) {
      if (!event.target.classList.contains('customer-emails-holder') && !event.target.classList.contains('customer-email-item-link')
        && !event.target.classList.contains('email-dropdown-menu') && !event.target.classList.contains('previous-emails-titles')) {
        this.showEmailMenu = false
      }

      if (!event.target.classList.contains('customer-phones-holder') && !event.target.classList.contains('customer-phone-item') && !event.target.classList.contains('phone-dropdown-menu') && !event.target.classList.contains('previous-phones-titles')) {
        this.showPhoneMenu = false
      }
    },
    setCurrentCustomer(index) {
      if (index === undefined && this.customer.owner) {
        this.currentCustomer = this.customer.customers.find(c => c.id === this.customer.owner.id) ?? this.customer.customers[0]
      } else {
        this.currentCustomer = this.customer.customers[index ?? 0]
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@/views/scooters/scss/userCard.scss';
</style>
