<template>
  <b-col
    cols="7"
    md="4"
  >
    <b-card>

      <b-row no-gutters>

        <b-col cols="6">
          <div class="data_wrapper scooter_name_wrapper">
            {{ scooter.name || '-' }}
          </div>

          <b-badge
            variant="light-primary"
            class="scooter-badge"
          >
            {{ scooter.type || '-' }}
          </b-badge>
          <div
            class="row user-data-row is-stolen-div"
          >
            <b-col cols="12">
              <span class="label">
                Status:
              </span>
              <span class="data">
                <b-badge
                  :variant="scooter.is_stolen ? 'light-danger' : 'light-success'"
                  class="scooter-badge is-stolen-btn"
                  @click="stolenMode"
                >
                  {{ scooter.is_stolen ? 'Stolen' : 'Unstolen' }}
                </b-badge>
                <b-spinner
                  v-if="stolenLoading"
                  small
                  label="Loading..."
                />
              </span>

            </b-col>
          </div>

          <b-button
            v-b-tooltip="'Send Pin'"
            class="send-pin-button w-100 mt-1"
            @click="send_pin"
          >
            Send Pin
          </b-button>

          <!-- <div class="send-pin-hashcode mb-1">
            Pin code: {{ scooter.password }}
          </div> -->
        </b-col>

        <b-col class="reset-pin-hashcode-column">
          <div>
            <b-dropdown
              id="customers-dropdown"
              v-b-tooltip="'Reset Pin'"
              text="Reset Pin"
              class="user-btn-large reset-pin-hashcode ml-2 button-white"
            >
              <b-dropdown-item
                v-for="customer in scooter.customers"
                :key="customer.id"
                @click="reset_pin(customer.id)"
              >
                {{ customer.email }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col cols="6">

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Boot:
              </span>
              <span class="data">
                {{ scooter.sensorsInfo && scooter.sensorsInfo.escInfo && scooter.sensorsInfo.escInfo.boot ? scooter.sensorsInfo.escInfo.boot : "-" }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Serial:
              </span>
              <span class="data">
                {{ scooter.serial ? scooter.serial : "-" }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Firmware:
              </span>
              <span class="data">
                {{ scooter.sensorsInfo && scooter.sensorsInfo.escInfo && scooter.sensorsInfo.escInfo.firmware ? scooter.sensorsInfo.escInfo.firmware : "-" }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Hardware:
              </span>
              <span class="data">
                {{ scooter.sensorsInfo && scooter.sensorsInfo.escInfo && scooter.sensorsInfo.escInfo.hardware ? scooter.sensorsInfo.escInfo.hardware : "-" }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Model:
              </span>
              <span class="data">
                {{ scooter.sensorsInfo && scooter.sensorsInfo.escInfo && scooter.sensorsInfo.escInfo.model ? scooter.sensorsInfo.escInfo.model : "-" }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Unicode:
              </span>
              <span class="data">
                {{ scooter.sensorsInfo && scooter.sensorsInfo.escInfo && scooter.sensorsInfo.escInfo.uniquecode ? scooter.sensorsInfo.escInfo.uniquecode : "-" }}
              </span>
            </b-col>
          </div>

        </b-col>
        <b-col cols="6">

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Total rides:
              </span>
              <span class="data">
                {{ scooter.rides || '0' }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Month to day:
              </span>
              <span class="data">
                {{ stats.current_month || '0' }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Year to day:
              </span>
              <span class="data">
                {{ stats.current_year || '0' }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Odometer:
              </span>
              <span class="data">
                <!-- odometer reversed with app_odometer upon derek resquest to applay ratio equation -->
                {{ scooter.app_odometer ? scooter.app_odometer.toFixed(2) : '0' }} km
              </span>
            </b-col>
          </div>
          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Ride odometer:
              </span>
              <span class="data">
                <!-- odometer reversed with app_odometer upon derek resquest to applay ratio equation -->
                {{  scooter.odometer ? scooter.odometer.toFixed(2) : '0' }} km {{ app_odometer_ratio }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Created Date:
              </span>
              <span class="data">
                {{ moment.utc(scooter.created_at).local().format('ll') || '-' }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Pin:
              </span>
              <span class="data">
                {{ scooter.password || '-' }}
              </span>
            </b-col>
          </div>

        </b-col>
      </b-row>

    </b-card>

  </b-col>
</template>

<script>
import Moment from 'moment'

export default {
  props: {
    scooter: {
      type: Object,
      default() {
        return {}
      },
    },
    stats: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      moment: Moment,
      should_update_firmware: null,
      stolenLoading: false,
    }
  },
  computed: {
    app_odometer_ratio() {
      if (this.scooter.odometer && this.scooter.app_odometer) {
        return ` (${((this.scooter.odometer/ this.scooter.app_odometer) * 100).toFixed(0)}% of rides are recorded)`
      }
      return ''
    },
  },
  watch: {
    scooter: {
      handler() {
        this.stolenLoading = false
      },
      deep: true,
    },
  },
  methods: {
    send_pin() {
      this.$http.post(this.$resources.scooters.sendPin, {
        scooter_id: this.scooter.id,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: 'Send pincode', text: 'Pincode sent successfully' })
      })
    },
    reset_pin(customerID) {
      this.$http.post(this.$resources.scooters.resetPin.replace(':id', this.scooter.id), {
        customer_id: customerID,
      }).then(response => {
        const { data: { status } } = response
        if (status === 920) {
          this.$notify({ type: 'success', title: 'Reset pincode', text: 'Reset pincode message sent successfully' })
        } else {
          this.$notify({ type: 'error', title: 'Reset pincode', text: 'Reset pincode not available for this customer' })
        }
      })
    },
    updateFirmware() {
      this.$http.post(this.$resources.scooters.update_firmware.replace(':id', this.scooter.id)).then(() => {
        this.$notify({ type: 'success', title: 'Framework update', text: 'Framework update loaded successfully' })
      })
    },
    stolenMode() {
      this.$swal({
        title: 'stolen mode',
        text: this.scooter.is_stolen ? 'Please confirm this scooter is unstolen' : 'Please confirm this scooter is stolen',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.stolenLoading = true
          this.$emit('isStolenChanged')
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .firmware-update-button {
    border:none;
    background-color: #cccccc !important;
    color:#1d1d1d !important;
    &.firmware-update-button:hover,  &.firmware-update-button:focus{
      color:#797979 !important;
      background-color: #bcbdc2 !important;
      box-shadow: none !important;
    }
  }

  .reset-pin-hashcode-column {
    display: flex !important;
    align-items: flex-end;
    .reset-pin-hashcode {
      background-color: #ffffff !important;
      color: #797979 !important;
      border: none !important;
      border: 1px solid transparent !important;
      height: 34px;
      .dropdown-toggle {
        width: fit-content !important;
        color: black !important;
        padding: 0;
        margin-top: -13px;
       }
      &:hover,:focus {
        color:#797979 !important;
        // border: 1px solid #797979 !important;
        // box-shadow: none !important;
      }
    }
  }

  .is-stolen-div {
    margin-top: 10px;
    .is-stolen-btn {
      cursor: pointer;
    }
  }
</style>
