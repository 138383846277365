<template>
  <b-col
    cols="12"
    md="5"
  >
    <travel-map
      v-if="markers.length > length"
      class="travel-map"
      :path-allowed="false"
      :last-location="true"
      :bluetooth-location="true"
      :markers="markers"
    />
  </b-col>
</template>

<script>
import {
  VBTooltip, BCol,
} from 'bootstrap-vue'
import TravelMap from '@/views/rides/components/Map/TravelMap.vue'
import Moment from 'moment'

export default {
  components: {
    BCol,
    TravelMap,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    scooter: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      markers: [],
      length: 0,
      moment: Moment,
    }
  },
  mounted() {
    if (this.scooter.iot_location !== null) {
      this.markers.push({
        icon: this.scooter.iot_location.icon,
        type: this.scooter.iot_location.type,
        position: {
          lat: this.scooter.iot_location.lat,
          lng: this.scooter.iot_location.lng,
          info: `<center><b>IOT location</b><br /> ${this.moment(this.scooter.iot_location.info).format('YYYY-MM-DD HH:mm:ss')}</center>`,
        },
      })
    }
    if (this.scooter.bluetooth_location !== null) {
      this.markers.push({
        icon: this.scooter.bluetooth_location.icon,
        type: this.scooter.bluetooth_location.type,
        position: {
          lat: this.scooter.bluetooth_location.lat,
          lng: this.scooter.bluetooth_location.lng,
          info: `<center><b>Bluetooth location</b> <br /> ${this.moment(this.scooter.bluetooth_location.info).format('YYYY-MM-DD HH:mm:ss')}</center>`,
        },
      })
    }

    if (this.scooter.iot_location && this.scooter.bluetooth_location) {
      this.length = 1
    }
  },
}
</script>
