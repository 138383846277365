<template>
  <div class="scooter-page-wrapper">
    <b-modal
      ref="scooter_users_modal"
      body-class="modal-body"
      modal-class="template-modal riders-modal"
      hide-header
      hide-footer
    >
      <div
        v-b-tooltip="'Close'"
        class="closer"
        @click="closeRidersModal"
      >
        <img
          class="close-icon-url"
          :src="require(`@/assets/images/icons/menu-icon/popup-icons/close-icon.svg`)"
          alt="close"
        >
      </div>
      <scooter-users-list
        :owner="scooterData.owner"
        :scooter-id="scooterData.id"
        :scooter-name="scooterData.name"
        @updateOwner="updateOwner"
      />
    </b-modal>
    <div
      v-if="!loading && scooterData && scooterData.is_stolen"
      class="scooter_is_stolen"
    >
      This scooter is stolen
    </div>

    <div class="paginator-container">
      <paginator
        target="scooters"
        :navigation-data="paginator"
        :updated="navigationUpdated"
      >
        Scooter
      </paginator>
      <div>
        <b-dropdown dropright>
          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="20"
              color="#818084"
            />
          </template>

          <b-dropdown-item
            @click="$refs.scooter_users_modal.show()"
          >
            List of all users
            <feather-icon
              style="padding-left:5px;"
              icon="UsersIcon"
              size="20"
              color="#818084"
            />
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div
      v-if="!loaded"
      class="single-scooter-loader"
    >
      <WidgetLoader />
    </div>
    <template v-else>
      <user-card
        v-if="scooterData !== undefined && scooterData.customers !== undefined && scooterData.customers.length > 0"
        :key="`customer-card-${scooterData.id}`"
        :customer="scooterData"
      />
      <div v-else>
        <b-card class="font-weight-bold no-customer-data">
          No customers assigned to this scooter
        </b-card>
      </div>
    </template>

    <b-row>
      <b-col
        v-if="!loaded"
        class="scooter-map-loader"
      >
        <WidgetLoader />
      </b-col>
      <scooter-model-data
        v-else
        :key="`scooter-card-${scooterData.id}`"
        :scooter="scooterData"
        :loaded="loaded"
        :stats="stats"
        @isStolenChanged="isStolenChanged"
      />

      <b-col
        v-if="!loaded"
        class="scooter-map-loader"
      >
        <WidgetLoader />
      </b-col>
      <scooter-warranty v-else />

      <b-col
        v-if="!loaded"
        class="scooter-map-loader"
      >
        <WidgetLoader />
      </b-col>
      <template v-else>
        <scooter-map
          v-if="scooterData.iot_location || scooterData.bluetooth_location"
          :key="`scooter-map-${scooterData.id}`"
          :scooter="scooterData"
        />
        <b-col
          v-else
          cols="12"
          md="5"
          class="no-last-location"
        >
          <b-card>
            <div>
              There is no last location for this scooter
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>

    <scooter-specifications
      :key="`scooter-specifications-${scooterData.id}`"
      :sensors-info="sensorsInfo"
      :stats="stats"
      :loaded="loaded"
    />
  </div>
</template>
<script>
import {
  BCard, VBTooltip, BRow,
} from 'bootstrap-vue'
import UserCard from '@/views/scooters/components/single-scooter/UserCard.vue'
import ScooterMap from '@/views/scooters/components/single-scooter/ScooterMap.vue'
import ScooterSpecifications from '@/views/scooters/components/single-scooter/ScooterSpecifications.vue'
import ScooterModelData from '@/views/scooters/components/single-scooter/ScooterModelData.vue'
import WidgetLoader from '@/views/components/WidgetLoader.vue'
import paginator from '@/views/shared-components/general/Paginator.vue'
import ScooterUsersList from '@/views/scooters/components/users-list/ScooterUsersList.vue'
import ScooterWarranty from '@/views/scooters/components/single-scooter/ScooterWarranty.vue'

export default {
  components: {
    ScooterWarranty,
    ScooterUsersList,
    BCard,
    BRow,
    UserCard,
    ScooterMap,
    ScooterSpecifications,
    ScooterModelData,
    WidgetLoader,
    paginator,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      scooterData: {},
      sensorsInfo: {},
      stats: {
        current_year: 0,
        current_month: 0,
        months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      iot_location: {
        lat: null,
        lng: null,
        info: null,
        type: 'last-location',
        icon: 'last-location.png',
      },
      bluetooth_location: {
        lat: null,
        lng: null,
        info: null,
        type: 'bluetooth-location',
        icon: 'bluetooth-location.png',
      },
      navigationUpdated: false,
      paginator: null,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    updateOwner(newOwnerId) {
      this.scooterData.owner = this.scooterData.customers.find(c => c.id === newOwnerId) ?? null
    },
    closeRidersModal() {
      this.$refs.scooter_users_modal.hide()
    },
    isStolenChanged() {
      this.$http.post(this.$resources.scooters.stolen.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.scooterData.is_stolen = data.current_status
        }
      })
    },
    assignData() {
      if (this.scooterData.bluetooth_location && this.scooterData.bluetooth_location.lat !== 0 && this.scooterData.bluetooth_location.lng !== 0) {
        this.bluetooth_location.lat = this.scooterData.bluetooth_location.lat
        this.bluetooth_location.lng = this.scooterData.bluetooth_location.lng
        this.bluetooth_location.info = this.scooterData.bluetooth_location.timeStamp
        this.scooterData.bluetooth_location = this.bluetooth_location
      } else {
        this.scooterData.bluetooth_location = null
      }

      if (this.scooterData.iot_location && this.scooterData.iot_location.lat !== 0 && this.scooterData.iot_location.lng !== 0) {
        this.iot_location.lat = this.scooterData.iot_location.lat
        this.iot_location.lng = this.scooterData.iot_location.long
        this.iot_location.info = this.scooterData.iot_location.timestamp
        this.scooterData.iot_location = this.iot_location
      } else {
        this.scooterData.iot_location = null
      }
    },
    load() {
      this.loaded = false
      this.$http.get(this.$resources.scooters.fetch.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.scooterData = data.scooter
          this.assignData()
          this.sensorsInfo = JSON.parse(data.scooter.sensorsInfo)
          this.scooterData.sensorsInfo = this.sensorsInfo
          this.loading = false
          this.loaded = true
        }
      })
      this.$http.get(this.$resources.scooters.scooterStats.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.stats = data
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .scooter-page-wrapper {

    .navigator-btn {
          cursor: pointer;
          font-size: 36px;
          padding: 5px 15px;
          text-align: center;
          vertical-align: middle;
          border: transparent;
          background-color: transparent;
          &:hover{
              color: gray;
          }
      }

    .travel-map {
      height: 100% !important;
      width: 100% !important;
    }

  }
  .scooter_is_stolen {
    color: #151210 !important;
    background-color: #F5A2A2;
    text-align: center !important;
    padding:16px;
    font-weight: 600;
    border-radius: 6px;
    margin-bottom: 10px !important;
  }

  .no-last-location {
    .card{
      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 438px !important;
        font-weight: 550;
      }
    }
  }
  .scooter-map-loader {
    .card{
      width: 100%;
      .card-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 356px !important;
        font-weight: 550;
      }
    }
  }

  .no-customer-data {
      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 228px !important;
      }
  }

  // media queries
  @media screen and (max-width: 767px) {
  .scooter-page-wrapper {
    .travel-map {
      height: 400px !important;
    }
  }
}

.paginator-container {
  display: flex;
  align-items: center;
}

.riders-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .closer {
    float: right;
    cursor:pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

</style>
