<template>
  <b-row class="specifications-columns">
    <b-col
      v-if="!loaded"
      class="specifications-loader"
    >
      <WidgetLoader />
    </b-col>
    <b-col
      v-else
      cols="12"
      md="4"
    >
      <b-card>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Accelerated Throttle Response:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.acceleratedThrottleResponse
                ? sensorsInfo.fullAdvParams.acceleratedThrottleResponse : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Accelerated Brake Response:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.acceleratorBrakeResponse
                ? sensorsInfo.fullAdvParams.acceleratorBrakeResponse : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Cruise Control:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.cruiseSw
                ? "On" : "Off" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Cruise Time:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.cruiseTime
                ? sensorsInfo.fullAdvParams.cruiseTime : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Last Service Mileage:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.lastServiceMileage
                ? sensorsInfo.fullAdvParams.lastServiceMileage : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Limited Speed Value:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.limitedSpeedValue
                ? sensorsInfo.fullAdvParams.limitedSpeedValue : "0" }}
            </span>
          </b-col>
        </div>
      </b-card>
    </b-col>

    <b-col
      v-if="!loaded"
      class="specifications-loader"
    >
      <WidgetLoader />
    </b-col>

    <b-col
      v-else
      cols="12"
      md="4"
    >
      <b-card>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Max Braking Current:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.maxBrakingCurrent
                ? sensorsInfo.fullAdvParams.maxBrakingCurrent : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Mac Discharge Current:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.maxDischargeCurrent
                ? sensorsInfo.fullAdvParams.maxDischargeCurrent : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Max Modulation Depth:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.maxModulationDepth
                ? sensorsInfo.fullAdvParams.maxModulationDepth : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Metric Units:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.metric
                ? "On" : "Off" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Motor Diameter:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.motorDiameter
                ? sensorsInfo.fullAdvParams.motorDiameter : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Motor Pole Pairs:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.motorPolePairs
                ? sensorsInfo.fullAdvParams.motorPolePairs : "0" }}
            </span>
          </b-col>
        </div>
      </b-card>
    </b-col>

    <b-col
      v-if="!loaded"
      class="specifications-loader"
    >
      <WidgetLoader />
    </b-col>

    <b-col
      v-else
      cols="12"
      md="4"
    >
      <b-card>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Pwm Frequency:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.pwmFrequency
                ? sensorsInfo.fullAdvParams.pwmFrequency : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Service Mileage:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.serviceMileage
                ? sensorsInfo.fullAdvParams.serviceMileage : "0" }} KM
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Shutdown Time:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.shutdownTime
                ? sensorsInfo.fullAdvParams.shutdownTime : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Voltage Protection:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.voltageProtection
                ? sensorsInfo.fullAdvParams.voltageProtection : "0" }}
            </span>
          </b-col>
        </div>
        <div class="row user-data-row">
          <b-col cols="12">
            <span class="label">
              Zero Start:
            </span>
            <span class="data">
              {{ sensorsInfo.fullAdvParams && sensorsInfo.fullAdvParams.zeroStart
                ? "On" : "Off" }}
            </span>
          </b-col>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    sensorsInfo: {
      type: Object,
      default() {
        return null
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>
<style lang="scss">
  @import '@/views/scooters/scss/scooterCard.scss';
</style>
