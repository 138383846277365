<template>
  <div v-if="!loading">
    <div class="view-title">
      List of all users
    </div>
    <div class="rider-table-title">
      Owner
    </div>
    <b-table
      :items="items.filter(item => owner && item.customerId === owner.id)"
      responsive
      :fields="fields.filter(field => field.key !== 'actions')"
      class="mb-0 tickets-card"
    />
    <div class="rider-table-title">
      Riders
    </div>
    <b-table
      :items="items.filter(item => !owner || item.customerId !== owner.id)"
      responsive
      :fields="fields"
      class="mb-0 tickets-card"
    >
      <template #cell(actions)="data">
        <div class="actions-icons">
          <img
            src="@/assets/images/icons/general-icons/transfer-owner.svg"
            alt="Transfer Ownership"
            title="Transfer Ownership"
            width="32"
            @click="transferOwner(data.item)"
          >
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import Moment from 'moment'

export default {
  components: {
  },
  props: {
    owner: {
      type: Object,
      default: null,
    },
    scooterId: {
      type: String,
      required: true,
    },
    scooterName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'Name',
          key: 'name',
        },
        {
          label: 'First Connect',
          key: 'firstConnect',
        },
        {
          label: 'Last Connect',
          key: 'lastConnect',
        },
        {
          label: 'Rides',
          key: 'rides',
        },
        {
          label: 'App',
          key: 'app',
        },
        {
          label: 'Action',
          key: 'actions',
        },
      ],
      items: [],
      loading: true,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$http.get(this.$resources.scooters.connections.replace(':id', this.scooterId)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.connections.map(c => ({
            name: c.customer.name,
            firstConnect: Moment(new Date(c.first_connection)).format('YYYY-MM-DD'),
            lastConnect: Moment(new Date(c.last_connection)).format('YYYY-MM-DD'),
            rides: c.rides,
            app: c.device,
            customerId: c.customer.id,
          }))
          this.loading = false
        }
      })
    },
    doTransferOwner(rider) {
      this.$http.post(this.$resources.scooters.changeOwner.replace(':id', this.scooterId), { customer_id: rider.customerId }).then(() => {
        this.$emit('updateOwner', rider.customerId)
      })
    },
    transferOwner(rider) {
      if (this.owner) {
        this.$swal({
          title: 'Transfer ownership',
          text: 'Are you sure you want to transfer ownership \n'
              + `of the scooter ${this.scooterName} from ${this.owner.name}\n`
              + `to ${rider.name}?`,
          showCancelButton: true,
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Yes, transfer ownership',
        }).then(result => {
          if (result.value) {
            this.doTransferOwner(rider)
          }
        })
      } else {
        this.doTransferOwner(rider)
      }
    },
  },
}
</script>
<style lang="scss">
.view-title {
  font-size: 16px;
  margin-top: 10px;
}
.rider-table-title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.actions-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    cursor: pointer;
  }
}
</style>
