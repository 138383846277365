<template>
  <b-col
    v-if="!warranty.start_date"
    class="scooter-map-loader"
  >
    <WidgetLoader />
  </b-col>
  <b-col v-else>
    <b-card>
      <b-row no-gutters>
        <b-col cols="6">
          <div class="data_wrapper scooter_name_wrapper">
            Warranty
          </div>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col cols="12">

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Status:
              </span>
              <span class="data">
                <b-badge
                  :variant="warranty.status === 'active' ? 'light-success' : 'light-danger'"
                  class="scooter-badge"
                >
                  {{ warranty.status }}
                </b-badge>
              </span>

            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Start Date:
              </span>
              <span class="data">
                {{ moment(warranty.start_date).format('YYYY-MM-DD') }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Expiration Date:
              </span>
              <span class="data">
                {{ moment(warranty.expiry_date).format('YYYY-MM-DD') }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Extend Coverage:
              </span>
              <span class="data">
                {{ warranty.extend }}
              </span>
            </b-col>
          </div>

          <div class="row user-data-row">
            <b-col cols="12">
              <span class="label">
                Purchase Channel:
              </span>
              <span class="data">
                {{ warranty.bought_from }}
              </span>
            </b-col>
          </div>

          <div v-if="warranty.isVoided">
            <div class="row user-data-row">
              <b-col cols="12">
                <span class="label">
                  Voided Date:
                </span>
                <span class="data">
                  {{ moment(warranty.voided_at).format('YYYY-MM-DD') }}
                </span>
              </b-col>
            </div>

            <div class="row user-data-row">
              <b-col cols="12">
                <span class="label">
                  Voided By:
                </span>
                <span class="data">
                  {{ warranty.voided_by.email }}
                </span>
              </b-col>
            </div>

            <div class="row user-data-row">
              <b-col cols="12">
                <span class="label">
                  Voided Reason:
                </span>
                <span class="data">
                  {{ warranty.voided_reason }}
                </span>
              </b-col>
            </div>

          </div>

        </b-col>
      </b-row>

      <div
        v-if="!warranty.isVoided"
        class="btn-black"
        @click="voidWarranty(warranty)"
      >
        Void the warranty
      </div>

    </b-card>

  </b-col>
</template>

<script>
import Moment from 'moment'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: { WidgetLoader },

  data() {
    return {
      moment: Moment,
      isLoading: true,
      warranty: {},
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    extendWarrantyPlanToString(planId) {
      return planId.slice(-2) === '2y' ? '2 years' : '1 year'
    },
    loadData() {
      this.isLoading = true
      this.$http.get(this.$resources.scooters.warranty.replace(':id', this.$route.params.id)).then(response => {
        this.isLoading = false
        const { data: { status, data } } = response
        if (status === 200) {
          this.warranty = data
          this.warranty.isVoided = !!data.voided_at
          this.warranty.extend = data.extended_warranty ? this.extendWarrantyPlanToString(data.extended_warranty.plan_id) : 'no coverage'
        }
      })
    },
    voidWarranty(warranty) {
      this.$swal({
        title: 'Void the warranty',
        html: 'Are you sure?<br /><br />Please enter the reason:<br />',
        input: 'text',
        inputPlaceholder: 'Reason to void the warranty...',
        inputValidator(value) {
          if (!value) {
            return 'You need to enter a reason.'
          }
          return false
        },
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.warranty = {}
          this.$http.post(this.$resources.scooters.voidWarranty.replace(':id', warranty.id),
            { reason: result.value }).then(() => {
            this.loadData()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.btn-black {
  transition: all .1s;
  cursor: pointer;
  box-shadow: none;
  border-radius: 10px !important;
  width: fit-content;
  padding: 10px 25px;
  background-color: #151210;
  line-height: 18px;
  color:#FCFBFB;
  &:hover {
    background-color: #fff;
    color: #151210;
    outline: 1px solid #151210;
  }
}
</style>
